(function () {
  'use strict';

  class ModulesRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    modules() {
      return this.$resource(`${this.env.api}/module_documents`);
    }

    moduleId() {
      return this.$resource(`${this.env.api}/module_documents/:id`, {id: '@id'});
    }

    discussionEntries() {
      return this.$resource(`${this.env.api}/module_documents/:id/discussion_entries`, {id: '@id'});
    }

    myModules() {
      return this.$resource(`${this.env.api}/modules/my-modules`);
    }

    module() {
      return this.$resource(`${this.env.api}/module_documents/:id/:clone`, {id: '@module_document.id', clone: '@module_document.clone'}, {update: {method: 'PUT'}});
    }

    nodes() {
      return this.$resource(`${this.env.api}/module_documents/:id/resource_nodes`, {id: '@module_id'}, {update: {method: 'PUT'}});
    }

    resourceNodes() {
      return this.$resource(`${this.env.api}/module_documents/:id/resource_nodes`, {id: '@id'});
    }

    singleNode() {
      return this.$resource(`${this.env.api}/module_documents/:id/resource_nodes/:resource_node_id`, {id: '@module_id', resource_node_id: '@resource_node_id'}, {update: {method: 'PUT'}});
    }

    submit() {
      return this.$resource(`${this.env.api}/module_documents/:id/resource_nodes/:resource_node_id/submit`, {id: '@module_id', resource_node_id: '@resource_node_id'}, {update: {method: 'PUT'}});
    }

    goTo() {
      return this.$resource(`${this.env.api}/modules/:id/go-to-resource`, {id: '@id'}, {update: {method: 'PUT'}});
    }

    lastAccessedResource() {
      return this.$resource(`${this.env.api}/modules/:id/last-accessed-resource`, {id: '@id'}, {update: {method: 'PUT'}});
    }

    resourceNode() {
      return this.$resource(`${this.env.api}/modules/:id/resource-node/:resource_node_id/:assessment_results/:my_results`, {id: '@id', resource_node_id: '@resource_node_id', assessment_results: '@assessment_results', my_results: '@my_results'}, {update: {method: 'PUT'}});
    }

    resourceNodeDecision() {
      return this.$resource(`${this.env.api}/modules/:id/resource-node/:resource_node_id/student-decisions`, {id: '@id', resource_node_id: '@resource_node_id'}, {update: {method: 'PUT'}});
    }

    resourcePolls() {
      return this.$resource(`${this.env.api}/modules/:id/resource-node/:resource_node_id/polls/:my_results`, {id: '@id', resource_node_id: '@resource_node_id', my_results: '@my_results'}, {update: {method: 'PUT'}});
    }

    resourceLog() {
      return this.$resource(`${this.env.api}/modules/:id/resource-node/:resource_node_id/log`, {id: '@id', resource_node_id: '@resource_node_id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/modules/:id/lom-metadata`, {id: '@id'});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:ModulesRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ModulesRest', ModulesRest);
}());
